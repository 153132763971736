/*------------------------------------*\
  #ordercheck.html
\*------------------------------------*/
.order_title {
	padding-top: 15px;
	margin: 15px auto;
	text-align: center;
	font-weight: normal;
}
.order_No{
	font-size: 14px;
	margin: 15px 0;
	padding: 0 0 0 3%;
}
.order_subtitle{
	background-color: $subtitle_bc;
	color: #fff;
	padding: 10px 0 10px 3%;
	margin: 0;
	font-size: 15px;
	font-weight: normal;
}
.order_pd{
	border: 1px solid #333;
	background-color: #fff;
	margin: 10px 0;
}
.order_pd_detail, .order_pd_count, .order_pd_price{
	width: 100%;
	>img, >p:nth-child(1){
		float: left;
		width: 30%;
		box-sizing: border-box;
	}
	>div, >p:nth-child(2){
		float: left;
		width: calc( 100% - 30% - 10px);
		box-sizing: border-box;
	}
}
.order_pd_detail{
	img{
		border: 1px solid #333;
		margin: 10px 0 0 10px;
	}
	div p{
		padding-left: 15px;
		margin: 5px 0;
	}
}
.order_pd_count, .order_pd_price{
	p:nth-child(1){
		padding-left:25px;
		margin: 10px 0;
	}
	p:nth-child(2){
		padding-left: 30px;
		margin: 10px 0;
	}
	>div{
		width: 35%;
		margin-left: 25px; 
	}
}
.order_pd_price p:nth-child(2){
	font-weight: bold;
	font-size: 16px;
}
.order_pd_clear{
	a{
		display: block;
		float: right;
		margin: 10px;
		padding: 10px 15px;
		background-color: #303030;
		color: #fff;
		box-shadow: 1px 1px 5px #c8c8c8;
	}
}
.order_sum{
	>p{
		text-align: right;
		margin: 5px 0;
		font-size: 14px;
	}
}
.order_note{
	width: 80%;
	margin: 20px auto;
	>div{
		color: red;
		font-size: 12px;
		margin: 30px 0;
		text-align: justify;
	}
}
.btn_arrowR{
	&:after{
		position: absolute;
		content: "〉";
		color: #fff;
		top: 0;
		right: 3px;
		font-size: 15px;
		font-weight: bold;
	}
}
.btn_arrowL{
	&:after{
		position: absolute;
		content: "〈";
		color: #333;
		top: 0;
		left: 3px;
		font-size: 15px;
		font-weight: bold;
	}
}
.bottom_line{
	width:100%;
	border-bottom: $border;
}

/*------------------------------------*\
  #orderpay.html
\*------------------------------------*/
.order_prefer{
	width: 80%;
	margin: 20px auto;
	>.selectwrap, >a, >div{
		margin: 30px auto;
	}
	>div{
		font-size: 12px;
		text-align: justify;
		ol{
			padding-left: 20px;
		}
	}
}

/*------------------------------------*\
  #orderinfo.html
\*------------------------------------*/
.inputbox{
	margin: 15px 0;
	>label{
		float: left;
		width: 30%;
		box-sizing: border-box;
		line-height: 35px;
	}
	>input{
		float: left;
		width: 70%;
		box-sizing: border-box;
		border: 1px solid #c1c1c1;
	}
	>.inputbox-note{
		width: 100%;
		float: left;
		padding: 5px 0 0 31%;
		margin: 0;
		font-size: 13px;

	}
}
.pbox{
	margin: 15px 0;
	p{
		line-height: 35px;
		margin: 0;
		&:nth-child(1){
			float: left;
			width: 30%;
			box-sizing: border-box;
		}
		&:nth-child(2){
			float: left;
			width: 70%;
			box-sizing: border-box;
		}
	}
	

}
.inputbox_addr{
	margin: 15px 0;
	>label{
		float: left;
		width: 25%;
		box-sizing: border-box;
		line-height: 55px;
	}
	>.inputbox_addrInfo{
		float: left;
		width: 75%;
		box-sizing: border-box;
	}

}
.inputbox_addrInfo{
	>div, >input{
		width: 100%;
		margin: 10px 0;
		box-sizing: border-box;
	}
	>input{
		border: 1px solid #c1c1c1;
		line-height: 25px;
	}
	>div{
		.area{
			float: left;
			width:  50%;
			box-sizing: border-box;
		}
		input{
			float: left;
			width: calc( 50% - 10px);
			margin-left: 10px;
			box-sizing: border-box;
			line-height: 25px;
			border: 1px solid #c1c1c1;
			background-color: #c1c1c1;
		}
	}

}
.shipping_point{
	p{
		font-size: 14px;
		span{
			color: #ed80ac;
			font-size: 26px;
		}
	}
	input{
		border: 2px solid #ed80ac;
		color: #ed80ac;
		width: 100%;
		line-height: 30px;
		padding-left: 15px;
		box-sizing: border-box;
	}
}
.pay_info{
	p{
		&:nth-child(2n-1){
			background-color: #efefef;
			font-weight: bold;
			padding: 10px 0 10px 3%;
			font-size: 14px;
		}
		&:nth-child(2n){
			padding: 5px 0 5px 3%;
			font-size: 14px;
			line-height: 15px;
		}
	}
}
.invoice{
	>p{
		background-color: #efefef;
		font-weight: bold;
		padding: 10px 0 10px 3%;
		font-size: 14px;
		&:nth-child(1){
			margin-top: 0;
		}
	}
	.invoice_type{
		>div{
			float: left;
			width: 50%;
			input[type="radio"]{
				margin-right: 5px;
			}
		}
	}
}
.invoice_carrier{
	>p, >.selectwrap, >input{
		float: left;
		box-sizing: border-box;
	}
	>p{
		width: 25%;
	}
	.selectwrap{
		width: 50%;
	}
	>input{
		width: 100%;
		border: $border;
		line-height: 35px;
	}
}

/*------------------------------------*\
  #orderfinish.html
\*------------------------------------*/
.finishOrder_info{
	p{
		&:nth-child(2n-1){
			background-color: #efefef;
			font-weight: bold;
			padding: 10px 0 10px 3%;
			font-size: 14px;
		}
		&:nth-child(2n){
			padding: 5px 0 5px 3%;
			font-size: 14px;
			line-height: 15px;
		}
		&:nth-child(1){
			margin-top: 0;
		}
		span{
			text-decoration: underline;			
		}
	}
}
.handling{
	color: #ed80ac;
	font-weight: bold;
}
.finishOrder_infoBox{
	border: $border;
	border-radius: 3px;
	box-shadow: 1px 1px 5px #ddd, -1px -1px 5px #ddd;
	background-color: #fff;
	margin: 10px 0;
	padding: 5px;
	table{
		width: 90%;
		margin: 10px auto;
		text-align: center;
		tr{
			th{
				background-color: #f3f4f6;
				border: 1px solid #fff;
			}
			th, td{
				width: 33%;
				padding: 5px;
			}
		}	
	}
}
.infoBox_pdDetail{
	margin: 5px 0;
	img, div{
		float: left;
	}
	img{
		width: 25%;
	}
	>div{
		width: 75%;
		>div{
			width: 100%;
			p{
				float: left;
				margin:0;
				padding: 3px 0 3px 3px;
				box-sizing: border-box;
				&:nth-child(1){
					width: 30%;
				}
				&:nth-child(2){
					width: 70%;
				}
			}
		}
	}
}
.infoBox_pdPrice{
	p{
		float: left;
		width: 50%;
		box-sizing: border-box;
		margin: 0;
		padding: 5px;
		&:nth-child(2){
			text-align: right;
		}
	}
	
}
.pink_color{
	background-color: #fff2f2;
	color: #fd0168;
	font-weight: bold;
}