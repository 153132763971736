$header_height: 84px;
$input_padding: 7px;
$td_padding: 7px;

$line_color: #ddd;
$main_gray: #858686;

$red: #fe0003;
$dark_red: #a51924;
$pink: #f4728c;
$green: #1aa54c;

$normal_transition_time: .3s;

$border: 1px solid $line_color;

$content_bc: #f1f1f1;
$subtitle_bc: #484848;

$padding: 10px 3%;