/*------------------------------------*\
  #General
\*------------------------------------*/
body {
	background: #fff;
	box-sizing: border-box;
	font-family: "ＭＳ Ｐゴシック", "MS PGothic", "Osaka", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "微軟正黑體", Arial, sans-serif;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none;
	color: #333;
}
input[type="text"],input[type="email"],input[type="password"],input[type="tel"],input[type="number"], textarea {
	border-radius: 0;
	padding: $input_padding;
	background-color: #fff;
	border: solid 1px #000;
}

img {
	max-width: 100%;
}
select {
	line-height: 28px;
	border: 0;
	border-radius: 0;
}

.mfp-close-btn-in .mfp-close {
	font-size: 0;
	line-height: 0;
	background: url(../images/icons/mfp-x.png) no-repeat center center;
}
.slick-dotted.slick-slider {
	margin: 0;
}

.pageWrap {
	width: 100vw;
	overflow: hidden;
	&.menuOpened {
		.offcanvas {
			transform: translateX(0);
			transform: translate3d(0,0,0);
		}
		.contentWrap {
			transform: translateX(72vw);
			transform: translate3d(72vw,0,0);
		}
		header {
			transform: translateX(72vw);
			transform: translate3d(72vw,0,0);
		}
		.pageCover {
			display: block;
			z-index: 3;
		}
		
	}
	&.searchOpened {
		.search_wrap {
			display: block;
			margin-top: -17px;
		}
		.pageCover {
			display: block;
			top: $header_height;
			height: calc(100vh - #{$header_height});
		}
	}
}
.contentWrap {
	width: 100vw;
	margin-top: 66px;
	transform: translateX(0);
	transform: translate3d(0,0,0);
	transition: all $normal_transition_time ease-in;
	box-sizing: border-box;
}
.pageCover {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: rgba(50,50,50,.6);
	display: none;
	z-index: 1;
}
.pageWrap_inner{
	padding: 0 3%;
	width: 100%; 
}
.sliderWrap {
	img {
		width: 100%;
	}
}

.btn {
	position: relative;
	background-color: #000;
	color: #fff;
	display: block;
	font-size: 15px;
	font-weight: bold;
	line-height: 40px;
	text-align: center;
	border: 0;
	border-radius: 0;
	&:last-child {
		margin: 0 0 30px;
	}
}
.btn_frame {
	position: relative;
	border: 1px solid #c2c2c2;
	border-radius: 3px;
	background-color: #f3f3f3;
	color: #000;
	display: block;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	line-height: 40px;
	&:last-child {
		margin: 0 0 30px;
	}
}
.btn + .btn_frame {
	margin-top: 15px;
}

.selectwrap {
	display: block;
	position: relative;
	select {
		width: 100%;
		height: 41px;
		border-radius: 0;
		background: #fff;
		padding: $input_padding;
		border: $border;
	}
	&.light {
		border: solid 1px #c9caca;
		select {
			color: $main_gray;	
		}
	}
}
.pagination {
	display: flex;
	border-bottom: $border;
	justify-content: space-between;
	padding: 10px 0;
	margin: 0 3%;
	>div{
		width: 12%;
		&:nth-child(2){
			width: 76%;
			text-align: center;
		}
	}
	a {
		display: block;
	}
	> div a	img {
		width: 100%;
	}
	ul {
		li {
			position: relative;
			display: inline-block;
			font-size: 18px;
			border: $border;
			padding: 3px;
			margin-bottom: 3px;
			width: 20px;
			height: 22px;
			font-weight: bolder;
			background-color: #f0f0f0;
			&.active {
				background-color: #fff;
				border: none;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}

.linkstyle {
	text-decoration: underline;
	color: $main_gray;
	font-size: 12px;
	text-align: right;
}
.forgetPW{
	margin: -10px 15px 10px;
	color: $main_gray;
	font-size: 12px;
}
#backToTop {
	position: fixed;
	bottom: 20px;
	right: 50px;
	display: block;
	width: 35px;
	height: 35px;
	background: url(../images/icons/backtop.png) no-repeat center center;
}

.title {
	padding: 30px 0;
	margin: 0;
	font-size: 20px;
	text-align: center;
	color: #000;
}

.inputbox + .linkstyle {
	margin: 0 0 15px;
	display: block;
}
.checkboxbox {
	margin: 0 0 15px;
	label {
		font-size: 13px;
	}
	input {
		vertical-align: middle;
		margin: 0 5px 0 0;
	}
}

.paddingAround {
	padding: 15px 3%;
	background-color: $content_bc;
}
.paddingAround_white {
	padding: 15px 3%;
}
.note {
	color: $main_gray;
	text-align: center;
	font-size: 12px;
}

.redTxt {
	color: $red;
}
.darkRedTxt {
	color: $dark_red;
}
.pinkTxt {
	color: $pink;
}
.greenTxt {
	color: $green;
}
.ads {
	margin: 30px 0;
	display: block;
	img {
		width: 100%;
	}
}
