/*------------------------------------*\
  #deafult.html 首頁
\*------------------------------------*/
.banner{
	margin-bottom: 10px;
}
.sliderWrap{
//點點
	ul.slick-dots{
		position: relative;
		bottom: 0px;
		margin: 5px;
		li button:before{
			color: #ddd;
			font-size: 10px;
			opacity: 1;
		}
		li.slick-active button:before{
			color: #93d4d6;
		}
	}
// 箭頭
	.slick-prev, .slick-prev:hover, .slick-prev:focus, .slick-next, .slick-next:hover, .slick-next:focus{
		width: 16px;
		height: 28px;
		background-image: url(../images/icons/slider_arrow_L.png);
		background-repeat:  no-repeat;
		background-size:  95%;
		top: auto;
		bottom: -20px;
		z-index: 5;
		&:before {
			display: none;
		}
	}
	.slick-prev, .slick-prev:hover, .slick-prev:focus{
		left: 15px;
	}
	.slick-next, .slick-next:hover, .slick-next:focus{
		right: 15px;
		background-image: url(../images/icons/slider_arrow_R.png);
	}
}





.ad_two{
	>div{
		float: left;
		width: 50%;
		&:nth-child(1){
			width: calc( 50% - 1px);
			border-right: 1px solid #333;
		}
	}

}
.bottom_line{
	height:1px;
	border-bottom: 1px solid #333;
}