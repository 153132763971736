/*------------------------------------*\
  #regist.html
\*------------------------------------*/
.shop_provision{
	border: $border;
	background-color: #f0f0f0;
	padding: 20px 3% 20px 3%;
	margin-bottom: 10px;
	>p{
		position: relative;
		font-size: 13px;
		font-weight: bolder;
		margin: 0px;
		padding: 0px;
	}
}	

.shop_provision{
	>p:after{
		content: "";
		display: block;
		position: absolute;
		font-weight: bolder;
		top: 5px;
		right: -5px;
		width: 30px;
		height: 15px;
		background: url(../images/icons/open.png) no-repeat center center;
		background-size: 40%;
	}
	&.active{
		border-bottom: none;
		margin-bottom: 0;
		p:after {
			content: "";
			background: url(../images/icons/close.png) no-repeat center center;
			background-size: 40%;
		}
	} 
}

div.shopProvision_submenu{
	display: none;
	background-color: #f0f0f0;
	width: 100%;
	font-size: 12px;
	padding: 10px;
	box-sizing: border-box;

	height: 150px;
	overflow: auto;

	&.active{
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		border: $border;
	}
	p{
		font-weight: normal;
		text-align: justify;
		
	}
	
}
.guide_order{
	text-decoration: underline;
}