/*------------------------------------*\
  #Header
\*------------------------------------*/
header {
	width: 100%;
	top: 0;
	left: 0;
	position: fixed;
    z-index: 3;
    background-color: #fff;
	border-bottom: $border;
	transform: translateX(0, 0, 0);
	transform: translate3d(0, 0, 0);
	transition: all $normal_transition_time ease-in;
	div {
		margin: 0;
		flex: 8;
		text-align: center;
		align-items: stretch;
		justify-content: center;
		a {
			display: flex;
			justify-content: center;
		}
		img {
			display: block;
			width: 85%;
			height: 85%;
		}
	}
}
.header_inner {
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
.header_icons{
	position: relative;
}
.header_icon {
	flex: 1;
	align-items: center;
	display: flex;
	&.menu {
		margin-right: auto;
		flex: 1;
		position: relative;
		span {
			position: absolute;
			width: 18px;
			height: 2px;
			background-color: #000;
			border-radius: 50px;
			top: 50%;
			margin-top: -1px;
			&:before, &:after {
				content: ' ';
				width: 18px;
				height: 2px;
				display: block;
				background-color: #000;
				position: absolute;
				left: 0;
			}
			&:before {
				top: -6px;
			}
			&:after {
				top: 6px;
			}
		}
	}
	&.search, &.bag {
		padding: 3px;
	}
	&.bag {
		div{
			background-image: url(../images/icons/shoppingbag.png);
			background-repeat: no-repeat;
			width: 30px;
			height: 30px;
			background-size: 100%;
		}
		span {
			display: block;
			color: red;
			font-size: 13px;
			padding: 10px 3px 0px 3px;
		}
	}
}
//左側memu
.offcanvas {
	position: fixed;
	width: 72vw;
	height: 100vh;
	overflow: auto;
	background-color: #fff;
	transform: translateX(-72vw);
	transform: translage3d(-72vw, 0 0);
	transition: transform $normal_transition_time ease-in;
	z-index: 4;
	box-sizing: border-box;
	top: 0;
	background-color: #000;
	a {
		display: block;
	}
	> ul {
		> li {
			> a {
				padding: 20px 40px;
				color: #fff;
			}
			li {
				padding: 0 10px;
				a {
					font-size: 14px;
					font-weight: normal;
					padding: 10px 40px;
					color: $main_gray;
					color: #fff;
				}
			}
		}
	}
}
//搜尋列
.search_wrap {
	width: 100vw;
	position: fixed;
	left: 0;
	top: calc(#{$header_height} - 1px);
	display: none;
	background-color: #fff;
	z-index: 2;
	border-top: solid 1px $line_color;
}
.search_inner {
	padding: 15px;
	display: flex;
	button {
		margin-left: 5px;
		border-radius: 5px;
		font-size: 10px;
	}
	input {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 1px solid $line_color;
		width: 80%;
		height: 20px;
	}
}
.submenu {
	display: none;
	&.active {
		display: block;
	}
}
//購物車
#popupBag{
	background-color: #f1f1f1;
	margin: 20px 3% 20px 3%;
	color: #333;
	position: relative;
	display: block;
	>.paddingAround{
		padding: 5px 3%;
	}
}
.two_btn{
	width: 100%;
	margin: 20px 0;
	>a{	
		margin: 0;
		&:nth-child(1){
			float: left;
			width: calc(40% - 10px);
			background-color: $line_color;
			margin: 0;			
		}
		&:nth-child(2){
			float: right;
			width: calc(60% - 10px);
			background-color: #fe6496;
			border: 1px solid #fe6496;
			color: #fff;			
		}
	}
}