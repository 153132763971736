/*------------------------------------*\
  #member.html 
\*------------------------------------*/
.member_info{
	border: 1px solid #bfbfbf;
	background-color: #ddd;
	>div{
		background-color: #fff;
		margin: 5px;
		border: 1px solid #bfbfbf;
		>p{
			margin: 0px;
			padding: 5px;
			line-height: 40px;
			background-color: #f1f1f1;
			padding-left: 15px;
			border-bottom: 1px solid #bfbfbf;
			font-weight: bold;
		}
		>div{
			margin: 15px 0;
			p{
				margin: 0px;
				padding: 3px 5px;
				&:nth-child(1){
					font-weight: bold;
				}
				&:nth-child(2){
					padding-left: 15px;
				}
			}
		}
		
	}
	.memberInfo_class{
		background-color: #fff;
	}
}
.memberInfo_menu{	
	display: block;
	li{
		border-bottom: 1px solid #ddd;
		padding: 5px 3% 5px 3%;
	}
	>li>a {
		line-height: 40px;
		span{
			position: relative;
			display: inline-block;
			width: 100%;
			line-height: 30px;
			&:after{
				content: "";
				display: block;
				position: absolute;
				font-weight: bolder;
				top: 5px;
				right: 0px;
				width: 30px;
				height: 15px;
				background: url(../images/icons/arrow_r.jpg) no-repeat center center;
				background-size: 95%;
			}
		}
	}
}

/*------------------------------------*\
  #modifyPassword.html 
\*------------------------------------*/
.modify_password{
	>p{
		
		background-color: #efefef;
		font-weight: bold;
		padding: 10px 0 10px 3%;
		font-size: 14px;
		&:nth-child(1){
			margin-top: 0;
		}
	}
	>div{
		padding: 0 3%;
		font-size: 14px;
		input{
			width: 100%;
			box-sizing: border-box;
			border: $border;
		}
		p{
			margin: 0;
			padding: 5px 0 10px;
		}
	}
}

/*------------------------------------*\
  #history.html 
\*------------------------------------*/
.history_pd{
	margin-bottom: 60px;
	p{
		margin: 0;
		padding: 10px 3%;
		border-bottom: $border;
		font-size: 15px;
		&:nth-child(1){
			background-color: #efefef;
			font-weight: bold;
		}
		a{
			text-decoration: underline;
		}
	}
}

/*------------------------------------*\
  #point.html 
\*------------------------------------*/
.point_box{
	>div{
		&:nth-child(1){
			background-color: #e6e6e6;
		}
		p{
			margin: 0;
			padding: 6px 3%;
			float: left;
			box-sizing: border-box;
			&:nth-child(1){
				width: 30%;
			}
			&:nth-child(2){
				width: 70%;
			}
			span{
				font-weight: bold;
			}
		}
	}
}

/*------------------------------------*\
  #newsletter.html 
\*------------------------------------*/
.newsletter{
	margin: 30px 0 50px 0;
	padding: 10px 3%;
	background-color: #f0f0f0;

	.newsletter_select{
		width: 100%;
		> .newsletter_brand{
			float: left;
			width: calc((100% - 3%*2)/3);
			margin: 5px 0;
			padding: 10px 0;
			background-color: #fff;
			box-sizing: border-box;
			&:nth-child(1), &:nth-child(2){
				margin-right: 3%;
			}
			label{
				img, input{
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
	>p{
		font-size: 13px;
	}
	>input[type="email"]{
		    width: 100%;
		    box-sizing: border-box;
		    margin: 0 0 15px 0;
		    border: 1px solid #ddd;
	}
}