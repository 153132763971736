/*------------------------------------*\
  #pdcontent.html
\*------------------------------------*/
.brandlogo{
	a{
		display: block;
	}
}
.pdcnt_detail{
	margin: 15px 0;
	.pdcnt_note{
		width: 50%;
		>img{
			float: left;
			width: calc( 20% - 6px);
			margin: 5px 3px;
		}
	}
	p{
		margin: 5px 0;
	}
}
.pdcnt_img {
	margin: 0 15px 15px;
	.pdcnt_slide{
		width: 90%;
		margin: 10px auto;
		img{
			width: 100%;
		}
	}
	
	.slick-prev, .slick-prev:hover, .slick-prev:focus, .slick-next, .slick-next:hover, .slick-next:focus{
		width: 16px;
		height: 28px;
		background-image: url(../images/icons/slider_arrow_L.png);
		background-repeat:  no-repeat;
		background-size:  100%;
		z-index: 5;
		&:before {
			display: none;
		}
	}
	.slick-prev, .slick-prev:hover, .slick-prev:focus{
		left: -7%;
	}
	.slick-next, .slick-next:hover, .slick-next:focus{
		right: -7%;
		background-image: url(../images/icons/slider_arrow_R.png);
	}
}
.pdcnt_img_list{
	width: 100%;
	>figure{
		display: inline-block;
		width: calc( 100% /6 - 6px );
		margin: 3px;
		img:hover{
			border: 1px solid #333;
		}
		
		figcaption{
			text-align: center;
			height: 35px;
			width: 100%;
			font-size: 10px;
			word-wrap: break-word; 
			overflow: hidden;
		}
	}	
}
.pdcnt_info {
	border-bottom: $border;
	padding: 20px 0;
	text-align: center;
	font-weight: bold;
}


.pdcnt_number {
	padding: 0 15px;
	margin: 0 0 15px;
	.selectwrap {
		border-color: #c9caca;
	}
}
.pdcnt_btn{
	height: 70px;
	padding: 0 15px;
	margin: 0 0 15px;
	
	a{
		display: block;
		text-align: center;
		line-height: 70px;
		font-size: 18px;
		color: #fff;
		background-color: #acacac;
		border-radius: 5px;
		box-shadow: 1px 1px 3px #333, -1px -1px 3px #ddd;
		&.putCart{
			background-color: #fe6496;
		}
	}

}

.pdcnt_about{
	>div{
		border-bottom: $border;
		padding: 30px 3% 30px 3%;
		margin-bottom: 2px;
		>p{
			position: relative;
			font-size: 14px;
			font-weight: bolder;
			margin: 0px;
			padding: 0px;
		}
	}	
}

.pda_menu{
	>p:after{
		content: "";
		display: block;
		position: absolute;
		font-weight: bolder;
		top: 5px;
		right: 0;
		width: 30px;
		height: 15px;
		background: url(../images/icons/open.png) no-repeat center center;
		background-size: 40%;
	}
	&.active{
		border-bottom: none;
		p:after {
			content: "";
			background: url(../images/icons/close.png) no-repeat center center;
			background-size: 40%;
		}
	} 
}
div.pda_submenu{
	display: none;
	width: 90%;
	font-size: 12px;
	margin: 10px 0;
	&.active{
		display: block;
		padding-top: 0;
	}
	&.pda_recommend{
		margin: 10px auto;
	}
	p{
		font-weight: normal;
	}
	
}

.pdcnt_measure{
	width: 90%;
	margin: 0 auto;
	text-align: center;
	overflow:auto;
	table{
		tr{
			border-top: $border;
			border-bottom: $border;
			th, td{
				padding: 5px 15px;
				font-size: 14px;
				font-weight: normal;
				white-space: nowrap;
				&:nth-child(1){
					background-color: #f2f2f2;

				}
			}
		}
	}
}
.recommend_slide{
	margin: 20px 0;
	>div div p{
		margin: 0;
		padding: 0 0 0 3px;
		font-size: 8px;
		font-weight: bolder;
		white-space : nowrap;
		text-overflow : ellipsis;
		overflow : hidden;
	}
	.slick-prev, .slick-prev:hover, .slick-prev:focus, .slick-next, .slick-next:hover, .slick-next:focus{
		width: 16px;
		height: 28px;
		background-image: url(../images/icons/slider_arrow_L.png);
		background-repeat:  no-repeat;
		background-size:  100%;
		z-index: 5;
		&:before {
			display: none;
		}
	}
	.slick-prev, .slick-prev:hover, .slick-prev:focus{
		left: -5%;
	}
	.slick-next, .slick-next:hover, .slick-next:focus{
		right: -5%;
		background-image: url(../images/icons/slider_arrow_R.png);
	}
}
.smallpop{
	background-color: #000;
	margin: 20px 3% 0 3%;
	padding: 20px 0;
	color: #fff;
	position: relative;
	display: block;
	text-align: center;
	input{
		width: 90%;
		margin: 15px auto;
		color: #333;
	}
}