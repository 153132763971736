/*------------------------------------*\
  #pdlist.html
\*------------------------------------*/
.pdlist_banner {
	padding: 10px 0 0 0;
	img {
		width: 100%;
	}
}
.pdlist_total{
	margin-top: 0;
	border-top: $border;
	border-bottom: $border;
}
.pdlist_select {
	margin: 10px 3%;
	width: 100%;
	>div{
		&:nth-child(1){
			float: left;
			width: 30%;
			height: 41px;
			line-height: 41px;
		}
		&:nth-child(2){
			float: left;
			width: 64%;
			text-align: right;
		}
	}
}
.pdlist_wrap {
	border-top: $border;
	margin: 0 3%;
	padding: 15px 0 0 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.pdlist_box {
	width: calc(50% - 5px);
	&:nth-child(2n-1){
		margin: 0 5px 15px 0;
	}
	&:nth-child(2n){
		margin: 0 0 15px 5px;
	}
	> div {
		&:nth-child(1) {
			height: 28px;
		}
		&:nth-child(1) {
			height: 24px;
		}
	}
	p {
		font-size: 14px;
		text-align: left;
		margin: .5em 0;
		&:nth-child(2) {
			max-height: 38px;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	.wishlist_img {
		position: relative;
	}
	.pdlist_cover {
		display: none;
	}
	&:hover {
		.pdlist_cover {
			display: flex;
		}
	}
}
.pdlist_note1{
	width: 80%;
	>img{
		float: left;
		width: calc( 25% - 6px);
		margin: 5px 3px;
	}
}
.pdlist_note2{
	text-align: right;
	>img{
		width: 30%;
	}
}