/*------------------------------------*\
  #Footer
\*------------------------------------*/
footer {
	background-color: #fff;
	.footer_menu{
		border-bottom: $border;
		
		padding: 30px 3% 30px 3%;
		margin: 0 0 2px;		
		> a {
			font-weight: bold;
			display: block;
			line-height: 18px;
			span {
				position: relative;
				display: inline-block;
				width: 100%;
				line-height: 30px;
				&:after {
					content: '';
					display: block;
					position: absolute;
					font-weight: bolder;
					top: 5px;
					right: 0px;
					width: 30px;
					height: 15px;
					background: url(../images/icons/open.png) no-repeat center center;
					background-size: 40%;
				}
			}	
		}
		&.active {
			> a span {
				&:after {
					content: "";
					background: url(../images/icons/close.png) no-repeat center center;
					background-size: 40%;
				}
			}
		}
	}
}
.footer_submenu{
	display: none;
	&.active {
		display: block;
	}
	li{
		border-bottom: 1px solid #ddd;
		padding: 5px 3% 5px 3%;
	}
	>li>a {
		line-height: 40px;
		span{
			position: relative;
			display: inline-block;
			width: 100%;
			line-height: 30px;
			&:after{
				content: "";
				display: block;
				position: absolute;
				font-weight: bolder;
				top: 5px;
				right: 0px;
				width: 30px;
				height: 15px;
				background: url(../images/icons/arrow_r.jpg) no-repeat center center;
				background-size: 95%;
			}
		}
	}
}
.footer_sns{
	padding: 0px;

}
.footer_social, .footer_toPC{
	float: left;
	margin: 0;
	text-align: center;
	padding: 10px 0px;
}
.footer_social{
	width: calc( 60% - 1px);
	border-right: $border;
	a img{
		width: 15%;
		margin-right:10px
	}
}
.footer_toPC {
	float: left;
	width: 40%;
	a img{
		width: 20%;
		margin-right:10px
	}
	a span{
		text-decoration: underline;
		font-size: 25px;
	}
}
.footer_bottom {
	background-color: #000;
	padding: 15px;
	margin: 0px;
	p {
		font-size: 12px;
		color: #fff;
		margin: 0;
	}
}